import { useEffect, useState } from "react";
import { SECONDS_MAGIC_LINK_COOLDOWN } from "shared/utils/constants";

import { useT } from "../../../../../i18n/use-t";
import { userHasCreatedAccount } from "../../../../../util/data-layer-actions";
import { Button } from "../../../../form/button";
import { Link } from "../../../../link";
import { Text } from "../../../../text";

interface SignUpDrawerProps {
  hasInitialTimeout: boolean;
  reTriggerEmail: () => void;
}

const PUBLIC_CHAT_LOCAL_STORAGE_KEY = "public-chat-credentials";

export const SignUpDrawer: React.FC<SignUpDrawerProps> = ({
  hasInitialTimeout,
  reTriggerEmail,
}) => {
  const t = useT();
  const [countdown, setCountdown] = useState(
    hasInitialTimeout ? SECONDS_MAGIC_LINK_COOLDOWN : 0,
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  useEffect(userHasCreatedAccount, []);

  return (
    <div className="flex flex-col items-center gap-2">
      <Button
        className="min-w-[100px]"
        isDisabled={countdown > 0}
        key="graph.choices.SIGNUP.resend"
        text={t(
          countdown === 0
            ? { tx: "graph.choice.SIGNUP.resend" }
            : {
                tx: "graph.choice.SIGNUP.resendIn",
                txData: { time: countdown },
              },
        )}
        onClick={() => {
          reTriggerEmail();
          setCountdown(SECONDS_MAGIC_LINK_COOLDOWN);
        }}
      />
      <Text
        className="label-text w-full py-2 font-normal"
        tx="graph.text.signup.info"
      />
      <Text
        className="label-text w-full py-2 font-normal"
        tx="graph.text.signup.restartChat"
        txComponents={{
          restartChat: (
            <Link
              onClick={async () => {
                localStorage.removeItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);
                location.reload();
              }}
            />
          ),
        }}
      />
    </div>
  );
};
