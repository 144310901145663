/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Account,
  Address,
  AddressDto,
  AttachPaymentMethodDto,
  CancelSubscriptionDto,
  ConnectAccountDto,
  Coordinates,
  CoordinatesDto,
  CreateActiveSubscriptionDto,
  CreateAndLinkAccountDto,
  CreateNewsStoryDto,
  CreateSocialMediaPostDto,
  CreateSubscriptionDto,
  CreditCard,
  Customer,
  DefaultPaymentMethodDto,
  DeleteDoctorDto,
  Doctor,
  FeedbacksDto,
  FinishedSignUpViaCallDto,
  GetMarkersDto,
  GetProrationInvoiceDto,
  GetUserDto,
  GroupInfoDto,
  GroupsAndSubscriptionsPerFollowedOrg,
  Invoice,
  IsFollowingTenant,
  LastActiveTenant,
  LinkAccount,
  LinkDoctorDto,
  MetabaseDashboardToken,
  MultipleMembershipsDto,
  NewSubscription,
  NewsStory,
  Notification,
  NotificationDto,
  PaymentMethod,
  RateDoctorDto,
  RegisterUserDto,
  RenewSubscriptionDto,
  ReportDto,
  SaveDoctorDto,
  Settings,
  SocialMediaComment,
  SocialMediaCommentDto,
  SocialMediaPost,
  SocialMediaPostCategory,
  SocialMediaPostIdDto,
  SocialMediaPostMedia,
  StripePrices,
  SubmitFeedbackDto,
  Subscription,
  Subscriptions,
  SwitchSubscriptionDto,
  TenantSettings,
  TimelineDto,
  UpdateNewsStoryDto,
  UpdateSettingsDto,
  UpdateSocialMediaPostDto,
  UpdateUserAttributesDto,
  UpdateUserDetailsDto,
  UpsertSocialMediaPostCategoryDto,
  User,
  UserCountryAndPostalCode,
  UserEventDto,
  UserExists,
  UserFeedback,
} from '../models/index';
import {
    AccountFromJSON,
    AccountToJSON,
    AddressFromJSON,
    AddressToJSON,
    AddressDtoFromJSON,
    AddressDtoToJSON,
    AttachPaymentMethodDtoFromJSON,
    AttachPaymentMethodDtoToJSON,
    CancelSubscriptionDtoFromJSON,
    CancelSubscriptionDtoToJSON,
    ConnectAccountDtoFromJSON,
    ConnectAccountDtoToJSON,
    CoordinatesFromJSON,
    CoordinatesToJSON,
    CoordinatesDtoFromJSON,
    CoordinatesDtoToJSON,
    CreateActiveSubscriptionDtoFromJSON,
    CreateActiveSubscriptionDtoToJSON,
    CreateAndLinkAccountDtoFromJSON,
    CreateAndLinkAccountDtoToJSON,
    CreateNewsStoryDtoFromJSON,
    CreateNewsStoryDtoToJSON,
    CreateSocialMediaPostDtoFromJSON,
    CreateSocialMediaPostDtoToJSON,
    CreateSubscriptionDtoFromJSON,
    CreateSubscriptionDtoToJSON,
    CreditCardFromJSON,
    CreditCardToJSON,
    CustomerFromJSON,
    CustomerToJSON,
    DefaultPaymentMethodDtoFromJSON,
    DefaultPaymentMethodDtoToJSON,
    DeleteDoctorDtoFromJSON,
    DeleteDoctorDtoToJSON,
    DoctorFromJSON,
    DoctorToJSON,
    FeedbacksDtoFromJSON,
    FeedbacksDtoToJSON,
    FinishedSignUpViaCallDtoFromJSON,
    FinishedSignUpViaCallDtoToJSON,
    GetMarkersDtoFromJSON,
    GetMarkersDtoToJSON,
    GetProrationInvoiceDtoFromJSON,
    GetProrationInvoiceDtoToJSON,
    GetUserDtoFromJSON,
    GetUserDtoToJSON,
    GroupInfoDtoFromJSON,
    GroupInfoDtoToJSON,
    GroupsAndSubscriptionsPerFollowedOrgFromJSON,
    GroupsAndSubscriptionsPerFollowedOrgToJSON,
    InvoiceFromJSON,
    InvoiceToJSON,
    IsFollowingTenantFromJSON,
    IsFollowingTenantToJSON,
    LastActiveTenantFromJSON,
    LastActiveTenantToJSON,
    LinkAccountFromJSON,
    LinkAccountToJSON,
    LinkDoctorDtoFromJSON,
    LinkDoctorDtoToJSON,
    MetabaseDashboardTokenFromJSON,
    MetabaseDashboardTokenToJSON,
    MultipleMembershipsDtoFromJSON,
    MultipleMembershipsDtoToJSON,
    NewSubscriptionFromJSON,
    NewSubscriptionToJSON,
    NewsStoryFromJSON,
    NewsStoryToJSON,
    NotificationFromJSON,
    NotificationToJSON,
    NotificationDtoFromJSON,
    NotificationDtoToJSON,
    PaymentMethodFromJSON,
    PaymentMethodToJSON,
    RateDoctorDtoFromJSON,
    RateDoctorDtoToJSON,
    RegisterUserDtoFromJSON,
    RegisterUserDtoToJSON,
    RenewSubscriptionDtoFromJSON,
    RenewSubscriptionDtoToJSON,
    ReportDtoFromJSON,
    ReportDtoToJSON,
    SaveDoctorDtoFromJSON,
    SaveDoctorDtoToJSON,
    SettingsFromJSON,
    SettingsToJSON,
    SocialMediaCommentFromJSON,
    SocialMediaCommentToJSON,
    SocialMediaCommentDtoFromJSON,
    SocialMediaCommentDtoToJSON,
    SocialMediaPostFromJSON,
    SocialMediaPostToJSON,
    SocialMediaPostCategoryFromJSON,
    SocialMediaPostCategoryToJSON,
    SocialMediaPostIdDtoFromJSON,
    SocialMediaPostIdDtoToJSON,
    SocialMediaPostMediaFromJSON,
    SocialMediaPostMediaToJSON,
    StripePricesFromJSON,
    StripePricesToJSON,
    SubmitFeedbackDtoFromJSON,
    SubmitFeedbackDtoToJSON,
    SubscriptionFromJSON,
    SubscriptionToJSON,
    SubscriptionsFromJSON,
    SubscriptionsToJSON,
    SwitchSubscriptionDtoFromJSON,
    SwitchSubscriptionDtoToJSON,
    TenantSettingsFromJSON,
    TenantSettingsToJSON,
    TimelineDtoFromJSON,
    TimelineDtoToJSON,
    UpdateNewsStoryDtoFromJSON,
    UpdateNewsStoryDtoToJSON,
    UpdateSettingsDtoFromJSON,
    UpdateSettingsDtoToJSON,
    UpdateSocialMediaPostDtoFromJSON,
    UpdateSocialMediaPostDtoToJSON,
    UpdateUserAttributesDtoFromJSON,
    UpdateUserAttributesDtoToJSON,
    UpdateUserDetailsDtoFromJSON,
    UpdateUserDetailsDtoToJSON,
    UpsertSocialMediaPostCategoryDtoFromJSON,
    UpsertSocialMediaPostCategoryDtoToJSON,
    UserFromJSON,
    UserToJSON,
    UserCountryAndPostalCodeFromJSON,
    UserCountryAndPostalCodeToJSON,
    UserEventDtoFromJSON,
    UserEventDtoToJSON,
    UserExistsFromJSON,
    UserExistsToJSON,
    UserFeedbackFromJSON,
    UserFeedbackToJSON,
} from '../models/index';

export interface AttachPaymentMethodRequest {
    mamaDisease: AttachPaymentMethodMamaDiseaseEnum;
    attachPaymentMethodDto: AttachPaymentMethodDto;
    mamaOrganisation?: AttachPaymentMethodMamaOrganisationEnum;
}

export interface CancelSubscriptionRequest {
    mamaDisease: CancelSubscriptionMamaDiseaseEnum;
    cancelSubscriptionDto: CancelSubscriptionDto;
    mamaOrganisation?: CancelSubscriptionMamaOrganisationEnum;
}

export interface CommentSocialMediaPostRequest {
    socialMediaCommentDto: SocialMediaCommentDto;
}

export interface ConnectAccountRequest {
    mamaDisease: ConnectAccountMamaDiseaseEnum;
    connectAccountDto: ConnectAccountDto;
    mamaOrganisation?: ConnectAccountMamaOrganisationEnum;
}

export interface CreateActiveSubscriptionRequest {
    mamaDisease: CreateActiveSubscriptionMamaDiseaseEnum;
    createActiveSubscriptionDto: CreateActiveSubscriptionDto;
    mamaOrganisation?: CreateActiveSubscriptionMamaOrganisationEnum;
}

export interface CreateAndLinkStripeAccountRequest {
    mamaDisease: CreateAndLinkStripeAccountMamaDiseaseEnum;
    createAndLinkAccountDto: CreateAndLinkAccountDto;
    mamaOrganisation?: CreateAndLinkStripeAccountMamaOrganisationEnum;
}

export interface CreateNewsStoryRequest {
    mamaDisease: CreateNewsStoryMamaDiseaseEnum;
    createNewsStoryDto: CreateNewsStoryDto;
    mamaOrganisation?: CreateNewsStoryMamaOrganisationEnum;
}

export interface CreateNotificationRequest {
    notificationDto: NotificationDto;
}

export interface CreateSocialMediaPostRequest {
    mamaDisease: CreateSocialMediaPostMamaDiseaseEnum;
    createSocialMediaPostDto: CreateSocialMediaPostDto;
    mamaOrganisation?: CreateSocialMediaPostMamaOrganisationEnum;
}

export interface CreateSocialMediaPostCategoryRequest {
    mamaDisease: CreateSocialMediaPostCategoryMamaDiseaseEnum;
    upsertSocialMediaPostCategoryDto: UpsertSocialMediaPostCategoryDto;
    mamaOrganisation?: CreateSocialMediaPostCategoryMamaOrganisationEnum;
}

export interface CreateSubscriptionRequest {
    mamaDisease: CreateSubscriptionMamaDiseaseEnum;
    createSubscriptionDto: CreateSubscriptionDto;
    mamaOrganisation?: CreateSubscriptionMamaOrganisationEnum;
}

export interface CreateUserRequest {
    mamaDisease: CreateUserMamaDiseaseEnum;
    mamaOrganisation?: CreateUserMamaOrganisationEnum;
}

export interface DeleteDoctorRequest {
    deleteDoctorDto: DeleteDoctorDto;
}

export interface DeleteNewsStoryRequest {
    id: string;
    mamaDisease: DeleteNewsStoryMamaDiseaseEnum;
    mamaOrganisation?: DeleteNewsStoryMamaOrganisationEnum;
}

export interface DeleteNotificationRequest {
    id: string;
}

export interface DeleteSocialMediaPostRequest {
    id: string;
    mamaDisease: DeleteSocialMediaPostMamaDiseaseEnum;
    mamaOrganisation?: DeleteSocialMediaPostMamaOrganisationEnum;
}

export interface DeleteSocialMediaPostCategoryRequest {
    id: string;
    mamaDisease: DeleteSocialMediaPostCategoryMamaDiseaseEnum;
    mamaOrganisation?: DeleteSocialMediaPostCategoryMamaOrganisationEnum;
}

export interface DeleteSocialMediaPostCommentRequest {
    id: string;
}

export interface FinishedSignUpViaCallRequest {
    mamaDisease: FinishedSignUpViaCallMamaDiseaseEnum;
    finishedSignUpViaCallDto: FinishedSignUpViaCallDto;
    mamaOrganisation?: FinishedSignUpViaCallMamaOrganisationEnum;
}

export interface GetAddressFromCoordinatesRequest {
    coordinatesDto: CoordinatesDto;
}

export interface GetCoordinatesFromAddressRequest {
    addressDto: AddressDto;
}

export interface GetCreditCardsRequest {
    mamaDisease: GetCreditCardsMamaDiseaseEnum;
    mamaOrganisation?: GetCreditCardsMamaOrganisationEnum;
}

export interface GetDynamicManifestRequest {
    disease: string;
}

export interface GetMarkersRequest {
    mamaDisease: GetMarkersMamaDiseaseEnum;
    getMarkersDto: GetMarkersDto;
    mamaOrganisation?: GetMarkersMamaOrganisationEnum;
}

export interface GetMeRequest {
    mamaDisease: GetMeMamaDiseaseEnum;
    mamaOrganisation?: GetMeMamaOrganisationEnum;
}

export interface GetMyDoctorsRequest {
    mamaDisease: GetMyDoctorsMamaDiseaseEnum;
    mamaOrganisation?: GetMyDoctorsMamaOrganisationEnum;
}

export interface GetNewsStoryRequest {
    id: string;
}

export interface GetPriceListRequest {
    mamaDisease: GetPriceListMamaDiseaseEnum;
    mamaOrganisation?: GetPriceListMamaOrganisationEnum;
}

export interface GetProrationInvoiceRequest {
    mamaDisease: GetProrationInvoiceMamaDiseaseEnum;
    getProrationInvoiceDto: GetProrationInvoiceDto;
    mamaOrganisation?: GetProrationInvoiceMamaOrganisationEnum;
}

export interface GetSocialMediaPostRequest {
    id: string;
}

export interface GetSocialMediaPostMediaRequest {
    socialMediaPostIdDto: SocialMediaPostIdDto;
}

export interface GetStripeAccountRequest {
    mamaDisease: GetStripeAccountMamaDiseaseEnum;
    mamaOrganisation?: GetStripeAccountMamaOrganisationEnum;
}

export interface GetStripeCustomerRequest {
    mamaDisease: GetStripeCustomerMamaDiseaseEnum;
    mamaOrganisation?: GetStripeCustomerMamaOrganisationEnum;
}

export interface GetTenantSettingsRequest {
    mamaDisease: GetTenantSettingsMamaDiseaseEnum;
    mamaOrganisation?: GetTenantSettingsMamaOrganisationEnum;
}

export interface GetUserRequest {
    mamaDisease: GetUserMamaDiseaseEnum;
    getUserDto: GetUserDto;
    mamaOrganisation?: GetUserMamaOrganisationEnum;
}

export interface GetUserOwnedSubscriptionsRequest {
    mamaDisease: GetUserOwnedSubscriptionsMamaDiseaseEnum;
    mamaOrganisation?: GetUserOwnedSubscriptionsMamaOrganisationEnum;
}

export interface GetiframeUrlTokenForAdminDashboardRequest {
    dashboardIndex: number;
    mamaDisease: GetiframeUrlTokenForAdminDashboardMamaDiseaseEnum;
    mamaOrganisation?: GetiframeUrlTokenForAdminDashboardMamaOrganisationEnum;
}

export interface IsUserFollowingTenantRequest {
    mamaDisease: IsUserFollowingTenantMamaDiseaseEnum;
    mamaOrganisation?: IsUserFollowingTenantMamaOrganisationEnum;
}

export interface JoinGroupRequest {
    mamaDisease: JoinGroupMamaDiseaseEnum;
    groupInfoDto: GroupInfoDto;
    mamaOrganisation?: JoinGroupMamaOrganisationEnum;
}

export interface LeaveGroupRequest {
    mamaDisease: LeaveGroupMamaDiseaseEnum;
    groupInfoDto: GroupInfoDto;
    mamaOrganisation?: LeaveGroupMamaOrganisationEnum;
}

export interface LikeSocialMediaPostRequest {
    socialMediaPostIdDto: SocialMediaPostIdDto;
}

export interface LinkDoctorRequest {
    linkDoctorDto: LinkDoctorDto;
}

export interface ListDraftNewsStoriesRequest {
    language: string;
    mamaDisease: ListDraftNewsStoriesMamaDiseaseEnum;
    mamaOrganisation?: ListDraftNewsStoriesMamaOrganisationEnum;
}

export interface ListFollowedOrganisationsRequest {
    mamaDisease: ListFollowedOrganisationsMamaDiseaseEnum;
}

export interface ListNewsStoriesRequest {
    language: string;
    mamaDisease: ListNewsStoriesMamaDiseaseEnum;
    mamaOrganisation?: ListNewsStoriesMamaOrganisationEnum;
}

export interface ListPinnedNewsStoriesRequest {
    language: string;
    mamaDisease: ListPinnedNewsStoriesMamaDiseaseEnum;
    mamaOrganisation?: ListPinnedNewsStoriesMamaOrganisationEnum;
}

export interface ListSocialMediaPostCategoriesRequest {
    mamaDisease: ListSocialMediaPostCategoriesMamaDiseaseEnum;
    mamaOrganisation?: ListSocialMediaPostCategoriesMamaOrganisationEnum;
}

export interface ListSocialMediaPostsRequest {
    language: string;
    mamaDisease: ListSocialMediaPostsMamaDiseaseEnum;
    mamaOrganisation?: ListSocialMediaPostsMamaOrganisationEnum;
}

export interface ListSocialMediaPostsCommentsRequest {
    socialMediaPostIdDto: SocialMediaPostIdDto;
}

export interface LogUserEventRequest {
    userEventDto: UserEventDto;
}

export interface MarkSignUpAsDoneRequest {
    mamaDisease: MarkSignUpAsDoneMamaDiseaseEnum;
    mamaOrganisation?: MarkSignUpAsDoneMamaOrganisationEnum;
}

export interface PinNewsStoryRequest {
    id: string;
    mamaDisease: PinNewsStoryMamaDiseaseEnum;
    mamaOrganisation?: PinNewsStoryMamaOrganisationEnum;
}

export interface PublishNewsStoryRequest {
    id: string;
    mamaDisease: PublishNewsStoryMamaDiseaseEnum;
    mamaOrganisation?: PublishNewsStoryMamaOrganisationEnum;
}

export interface RateDoctorRequest {
    rateDoctorDto: RateDoctorDto;
}

export interface RegisterUserRequest {
    registerUserDto: RegisterUserDto;
}

export interface RenewCurrentSubscriptionRequest {
    mamaDisease: RenewCurrentSubscriptionMamaDiseaseEnum;
    renewSubscriptionDto: RenewSubscriptionDto;
    mamaOrganisation?: RenewCurrentSubscriptionMamaOrganisationEnum;
}

export interface SaveDoctorRequest {
    mamaDisease: SaveDoctorMamaDiseaseEnum;
    saveDoctorDto: SaveDoctorDto;
    mamaOrganisation?: SaveDoctorMamaOrganisationEnum;
}

export interface SendReportViaEmailRequest {
    mamaDisease: SendReportViaEmailMamaDiseaseEnum;
    reportDto: ReportDto;
    mamaOrganisation?: SendReportViaEmailMamaOrganisationEnum;
}

export interface SendTimelineRequest {
    mamaDisease: SendTimelineMamaDiseaseEnum;
    timelineDto: TimelineDto;
    mamaOrganisation?: SendTimelineMamaOrganisationEnum;
}

export interface SetLastActiveTenantRequest {
    lastActiveTenant: LastActiveTenant;
}

export interface ShowFeedbackRequest {
    feedbacksDto: FeedbacksDto;
}

export interface SubmitUserFeedbackRequest {
    submitFeedbackDto: SubmitFeedbackDto;
}

export interface SwitchSubscriptionRequest {
    mamaDisease: SwitchSubscriptionMamaDiseaseEnum;
    switchSubscriptionDto: SwitchSubscriptionDto;
    mamaOrganisation?: SwitchSubscriptionMamaOrganisationEnum;
}

export interface UnpinNewsStoryRequest {
    id: string;
    mamaDisease: UnpinNewsStoryMamaDiseaseEnum;
    mamaOrganisation?: UnpinNewsStoryMamaOrganisationEnum;
}

export interface UpdateDefaultPaymentMethodRequest {
    mamaDisease: UpdateDefaultPaymentMethodMamaDiseaseEnum;
    defaultPaymentMethodDto: DefaultPaymentMethodDto;
    mamaOrganisation?: UpdateDefaultPaymentMethodMamaOrganisationEnum;
}

export interface UpdateNewsStoryRequest {
    id: string;
    mamaDisease: UpdateNewsStoryMamaDiseaseEnum;
    updateNewsStoryDto: UpdateNewsStoryDto;
    mamaOrganisation?: UpdateNewsStoryMamaOrganisationEnum;
}

export interface UpdateOrganisationMemebershipsRequest {
    mamaDisease: UpdateOrganisationMemebershipsMamaDiseaseEnum;
    multipleMembershipsDto: MultipleMembershipsDto;
    mamaOrganisation?: UpdateOrganisationMemebershipsMamaOrganisationEnum;
}

export interface UpdateSettingsRequest {
    mamaDisease: UpdateSettingsMamaDiseaseEnum;
    updateSettingsDto: UpdateSettingsDto;
}

export interface UpdateSocialMediaPostRequest {
    id: string;
    mamaDisease: UpdateSocialMediaPostMamaDiseaseEnum;
    updateSocialMediaPostDto: UpdateSocialMediaPostDto;
    mamaOrganisation?: UpdateSocialMediaPostMamaOrganisationEnum;
}

export interface UpdateSocialMediaPostCategoryRequest {
    id: string;
    mamaDisease: UpdateSocialMediaPostCategoryMamaDiseaseEnum;
    upsertSocialMediaPostCategoryDto: UpsertSocialMediaPostCategoryDto;
    mamaOrganisation?: UpdateSocialMediaPostCategoryMamaOrganisationEnum;
}

export interface UpdateUserAttributesRequest {
    mamaDisease: UpdateUserAttributesMamaDiseaseEnum;
    updateUserAttributesDto: UpdateUserAttributesDto;
    mamaOrganisation?: UpdateUserAttributesMamaOrganisationEnum;
}

export interface UpdateUserDetailsRequest {
    mamaDisease: UpdateUserDetailsMamaDiseaseEnum;
    updateUserDetailsDto: UpdateUserDetailsDto;
    mamaOrganisation?: UpdateUserDetailsMamaOrganisationEnum;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async aliveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async alive(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.aliveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async aliveCheckRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alive-check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async aliveCheck(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.aliveCheckRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async attachPaymentMethodRaw(requestParameters: AttachPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentMethod>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling attachPaymentMethod.');
        }

        if (requestParameters.attachPaymentMethodDto === null || requestParameters.attachPaymentMethodDto === undefined) {
            throw new runtime.RequiredError('attachPaymentMethodDto','Required parameter requestParameters.attachPaymentMethodDto was null or undefined when calling attachPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe-customer/attach-payment-method`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttachPaymentMethodDtoToJSON(requestParameters.attachPaymentMethodDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentMethodFromJSON(jsonValue));
    }

    /**
     */
    async attachPaymentMethod(requestParameters: AttachPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentMethod> {
        const response = await this.attachPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cancelSubscriptionRaw(requestParameters: CancelSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling cancelSubscription.');
        }

        if (requestParameters.cancelSubscriptionDto === null || requestParameters.cancelSubscriptionDto === undefined) {
            throw new runtime.RequiredError('cancelSubscriptionDto','Required parameter requestParameters.cancelSubscriptionDto was null or undefined when calling cancelSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/cancel-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelSubscriptionDtoToJSON(requestParameters.cancelSubscriptionDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cancelSubscription(requestParameters: CancelSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async commentSocialMediaPostRaw(requestParameters: CommentSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialMediaComment>> {
        if (requestParameters.socialMediaCommentDto === null || requestParameters.socialMediaCommentDto === undefined) {
            throw new runtime.RequiredError('socialMediaCommentDto','Required parameter requestParameters.socialMediaCommentDto was null or undefined when calling commentSocialMediaPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/comment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SocialMediaCommentDtoToJSON(requestParameters.socialMediaCommentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialMediaCommentFromJSON(jsonValue));
    }

    /**
     */
    async commentSocialMediaPost(requestParameters: CommentSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialMediaComment> {
        const response = await this.commentSocialMediaPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async connectAccountRaw(requestParameters: ConnectAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling connectAccount.');
        }

        if (requestParameters.connectAccountDto === null || requestParameters.connectAccountDto === undefined) {
            throw new runtime.RequiredError('connectAccountDto','Required parameter requestParameters.connectAccountDto was null or undefined when calling connectAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/connnect-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectAccountDtoToJSON(requestParameters.connectAccountDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async connectAccount(requestParameters: ConnectAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.connectAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createActiveSubscriptionRaw(requestParameters: CreateActiveSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createActiveSubscription.');
        }

        if (requestParameters.createActiveSubscriptionDto === null || requestParameters.createActiveSubscriptionDto === undefined) {
            throw new runtime.RequiredError('createActiveSubscriptionDto','Required parameter requestParameters.createActiveSubscriptionDto was null or undefined when calling createActiveSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/create-active-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateActiveSubscriptionDtoToJSON(requestParameters.createActiveSubscriptionDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createActiveSubscription(requestParameters: CreateActiveSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createActiveSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createAndLinkStripeAccountRaw(requestParameters: CreateAndLinkStripeAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkAccount>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createAndLinkStripeAccount.');
        }

        if (requestParameters.createAndLinkAccountDto === null || requestParameters.createAndLinkAccountDto === undefined) {
            throw new runtime.RequiredError('createAndLinkAccountDto','Required parameter requestParameters.createAndLinkAccountDto was null or undefined when calling createAndLinkStripeAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/link-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAndLinkAccountDtoToJSON(requestParameters.createAndLinkAccountDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkAccountFromJSON(jsonValue));
    }

    /**
     */
    async createAndLinkStripeAccount(requestParameters: CreateAndLinkStripeAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkAccount> {
        const response = await this.createAndLinkStripeAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createNewsStoryRaw(requestParameters: CreateNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsStory>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createNewsStory.');
        }

        if (requestParameters.createNewsStoryDto === null || requestParameters.createNewsStoryDto === undefined) {
            throw new runtime.RequiredError('createNewsStoryDto','Required parameter requestParameters.createNewsStoryDto was null or undefined when calling createNewsStory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news-stories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNewsStoryDtoToJSON(requestParameters.createNewsStoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsStoryFromJSON(jsonValue));
    }

    /**
     */
    async createNewsStory(requestParameters: CreateNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsStory> {
        const response = await this.createNewsStoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createNotificationRaw(requestParameters: CreateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationDto === null || requestParameters.notificationDto === undefined) {
            throw new runtime.RequiredError('notificationDto','Required parameter requestParameters.notificationDto was null or undefined when calling createNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationDtoToJSON(requestParameters.notificationDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createNotification(requestParameters: CreateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNotificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createSocialMediaPostRaw(requestParameters: CreateSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createSocialMediaPost.');
        }

        if (requestParameters.createSocialMediaPostDto === null || requestParameters.createSocialMediaPostDto === undefined) {
            throw new runtime.RequiredError('createSocialMediaPostDto','Required parameter requestParameters.createSocialMediaPostDto was null or undefined when calling createSocialMediaPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSocialMediaPostDtoToJSON(requestParameters.createSocialMediaPostDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createSocialMediaPost(requestParameters: CreateSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createSocialMediaPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createSocialMediaPostCategoryRaw(requestParameters: CreateSocialMediaPostCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialMediaPostCategory>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createSocialMediaPostCategory.');
        }

        if (requestParameters.upsertSocialMediaPostCategoryDto === null || requestParameters.upsertSocialMediaPostCategoryDto === undefined) {
            throw new runtime.RequiredError('upsertSocialMediaPostCategoryDto','Required parameter requestParameters.upsertSocialMediaPostCategoryDto was null or undefined when calling createSocialMediaPostCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/category`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertSocialMediaPostCategoryDtoToJSON(requestParameters.upsertSocialMediaPostCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialMediaPostCategoryFromJSON(jsonValue));
    }

    /**
     */
    async createSocialMediaPostCategory(requestParameters: CreateSocialMediaPostCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialMediaPostCategory> {
        const response = await this.createSocialMediaPostCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createSubscriptionRaw(requestParameters: CreateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewSubscription>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createSubscription.');
        }

        if (requestParameters.createSubscriptionDto === null || requestParameters.createSubscriptionDto === undefined) {
            throw new runtime.RequiredError('createSubscriptionDto','Required parameter requestParameters.createSubscriptionDto was null or undefined when calling createSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/create-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSubscriptionDtoToJSON(requestParameters.createSubscriptionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewSubscriptionFromJSON(jsonValue));
    }

    /**
     */
    async createSubscription(requestParameters: CreateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewSubscription> {
        const response = await this.createSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-creation/create-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deletUserFromCognitoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-deletion/delete-cognito-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletUserFromCognito(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletUserFromCognitoRaw(initOverrides);
    }

    /**
     */
    async deleteAllUserRecordsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-deletion/delete-all-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteAllUserRecords(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAllUserRecordsRaw(initOverrides);
    }

    /**
     */
    async deleteDoctorRaw(requestParameters: DeleteDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteDoctorDto === null || requestParameters.deleteDoctorDto === undefined) {
            throw new runtime.RequiredError('deleteDoctorDto','Required parameter requestParameters.deleteDoctorDto was null or undefined when calling deleteDoctor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteDoctorDtoToJSON(requestParameters.deleteDoctorDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDoctor(requestParameters: DeleteDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDoctorRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteNewsStoryRaw(requestParameters: DeleteNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNewsStory.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling deleteNewsStory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news-stories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNewsStory(requestParameters: DeleteNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNewsStoryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteNotificationRaw(requestParameters: DeleteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNotification(requestParameters: DeleteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNotificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteSocialMediaPostRaw(requestParameters: DeleteSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSocialMediaPost.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling deleteSocialMediaPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/post/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSocialMediaPost(requestParameters: DeleteSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSocialMediaPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteSocialMediaPostCategoryRaw(requestParameters: DeleteSocialMediaPostCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSocialMediaPostCategory.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling deleteSocialMediaPostCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSocialMediaPostCategory(requestParameters: DeleteSocialMediaPostCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSocialMediaPostCategoryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteSocialMediaPostCommentRaw(requestParameters: DeleteSocialMediaPostCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSocialMediaPostComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/comment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSocialMediaPostComment(requestParameters: DeleteSocialMediaPostCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSocialMediaPostCommentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async dismissUserFeedbackRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-feedback/dismiss-user-feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async dismissUserFeedback(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dismissUserFeedbackRaw(initOverrides);
    }

    /**
     */
    async finishedSignUpViaCallRaw(requestParameters: FinishedSignUpViaCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling finishedSignUpViaCall.');
        }

        if (requestParameters.finishedSignUpViaCallDto === null || requestParameters.finishedSignUpViaCallDto === undefined) {
            throw new runtime.RequiredError('finishedSignUpViaCallDto','Required parameter requestParameters.finishedSignUpViaCallDto was null or undefined when calling finishedSignUpViaCall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/signup/finish-via-call`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FinishedSignUpViaCallDtoToJSON(requestParameters.finishedSignUpViaCallDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async finishedSignUpViaCall(requestParameters: FinishedSignUpViaCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.finishedSignUpViaCallRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAddressFromCoordinatesRaw(requestParameters: GetAddressFromCoordinatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.coordinatesDto === null || requestParameters.coordinatesDto === undefined) {
            throw new runtime.RequiredError('coordinatesDto','Required parameter requestParameters.coordinatesDto was null or undefined when calling getAddressFromCoordinates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geo-location/address`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CoordinatesDtoToJSON(requestParameters.coordinatesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     */
    async getAddressFromCoordinates(requestParameters: GetAddressFromCoordinatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Address> {
        const response = await this.getAddressFromCoordinatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCoordinatesFromAddressRaw(requestParameters: GetCoordinatesFromAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Coordinates>> {
        if (requestParameters.addressDto === null || requestParameters.addressDto === undefined) {
            throw new runtime.RequiredError('addressDto','Required parameter requestParameters.addressDto was null or undefined when calling getCoordinatesFromAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geo-location/coordinates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressDtoToJSON(requestParameters.addressDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CoordinatesFromJSON(jsonValue));
    }

    /**
     */
    async getCoordinatesFromAddress(requestParameters: GetCoordinatesFromAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Coordinates> {
        const response = await this.getCoordinatesFromAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCreditCardsRaw(requestParameters: GetCreditCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CreditCard>>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getCreditCards.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe-customer/get-credit-cards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CreditCardFromJSON));
    }

    /**
     */
    async getCreditCards(requestParameters: GetCreditCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CreditCard>> {
        const response = await this.getCreditCardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDynamicManifestRaw(requestParameters: GetDynamicManifestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.disease === null || requestParameters.disease === undefined) {
            throw new runtime.RequiredError('disease','Required parameter requestParameters.disease was null or undefined when calling getDynamicManifest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tenant/{disease}/manifest.json`.replace(`{${"disease"}}`, encodeURIComponent(String(requestParameters.disease))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getDynamicManifest(requestParameters: GetDynamicManifestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getDynamicManifestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLastActiveTenantRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LastActiveTenant>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/last-active-tenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LastActiveTenantFromJSON(jsonValue));
    }

    /**
     */
    async getLastActiveTenant(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LastActiveTenant> {
        const response = await this.getLastActiveTenantRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getMarkersRaw(requestParameters: GetMarkersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Doctor>>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getMarkers.');
        }

        if (requestParameters.getMarkersDto === null || requestParameters.getMarkersDto === undefined) {
            throw new runtime.RequiredError('getMarkersDto','Required parameter requestParameters.getMarkersDto was null or undefined when calling getMarkers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/map-marker`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetMarkersDtoToJSON(requestParameters.getMarkersDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DoctorFromJSON));
    }

    /**
     */
    async getMarkers(requestParameters: GetMarkersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Doctor>> {
        const response = await this.getMarkersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMeRaw(requestParameters: GetMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getMe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async getMe(requestParameters: GetMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getMeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyDoctorsRaw(requestParameters: GetMyDoctorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Doctor>>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getMyDoctors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DoctorFromJSON));
    }

    /**
     */
    async getMyDoctors(requestParameters: GetMyDoctorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Doctor>> {
        const response = await this.getMyDoctorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNewsStoryRaw(requestParameters: GetNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsStory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNewsStory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news-stories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsStoryFromJSON(jsonValue));
    }

    /**
     */
    async getNewsStory(requestParameters: GetNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsStory> {
        const response = await this.getNewsStoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNotificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Notification>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationFromJSON));
    }

    /**
     */
    async getNotifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Notification>> {
        const response = await this.getNotificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPriceListRaw(requestParameters: GetPriceListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StripePrices>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getPriceList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/get-prices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StripePricesFromJSON(jsonValue));
    }

    /**
     */
    async getPriceList(requestParameters: GetPriceListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StripePrices> {
        const response = await this.getPriceListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProrationInvoiceRaw(requestParameters: GetProrationInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getProrationInvoice.');
        }

        if (requestParameters.getProrationInvoiceDto === null || requestParameters.getProrationInvoiceDto === undefined) {
            throw new runtime.RequiredError('getProrationInvoiceDto','Required parameter requestParameters.getProrationInvoiceDto was null or undefined when calling getProrationInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/get-proration-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetProrationInvoiceDtoToJSON(requestParameters.getProrationInvoiceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     */
    async getProrationInvoice(requestParameters: GetProrationInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invoice> {
        const response = await this.getProrationInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSocialMediaPostRaw(requestParameters: GetSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialMediaPost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSocialMediaPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialMediaPostFromJSON(jsonValue));
    }

    /**
     */
    async getSocialMediaPost(requestParameters: GetSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialMediaPost> {
        const response = await this.getSocialMediaPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSocialMediaPostMediaRaw(requestParameters: GetSocialMediaPostMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialMediaPostMedia>> {
        if (requestParameters.socialMediaPostIdDto === null || requestParameters.socialMediaPostIdDto === undefined) {
            throw new runtime.RequiredError('socialMediaPostIdDto','Required parameter requestParameters.socialMediaPostIdDto was null or undefined when calling getSocialMediaPostMedia.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/media`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialMediaPostIdDtoToJSON(requestParameters.socialMediaPostIdDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialMediaPostMediaFromJSON(jsonValue));
    }

    /**
     */
    async getSocialMediaPostMedia(requestParameters: GetSocialMediaPostMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialMediaPostMedia> {
        const response = await this.getSocialMediaPostMediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStripeAccountRaw(requestParameters: GetStripeAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getStripeAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe/get-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     */
    async getStripeAccount(requestParameters: GetStripeAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.getStripeAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStripeCustomerRaw(requestParameters: GetStripeCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getStripeCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe-customer/get-customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     */
    async getStripeCustomer(requestParameters: GetStripeCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Customer> {
        const response = await this.getStripeCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTenantSettingsRaw(requestParameters: GetTenantSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TenantSettings>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getTenantSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantSettingsFromJSON(jsonValue));
    }

    /**
     */
    async getTenantSettings(requestParameters: GetTenantSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantSettings> {
        const response = await this.getTenantSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getUser.');
        }

        if (requestParameters.getUserDto === null || requestParameters.getUserDto === undefined) {
            throw new runtime.RequiredError('getUserDto','Required parameter requestParameters.getUserDto was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetUserDtoToJSON(requestParameters.getUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserCountryAndPostalCodeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCountryAndPostalCode>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/city-and-postalcode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCountryAndPostalCodeFromJSON(jsonValue));
    }

    /**
     */
    async getUserCountryAndPostalCode(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCountryAndPostalCode> {
        const response = await this.getUserCountryAndPostalCodeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserOwnedSubscriptionsRaw(requestParameters: GetUserOwnedSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscriptions>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getUserOwnedSubscriptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/get-user-owned-subscriptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionsFromJSON(jsonValue));
    }

    /**
     */
    async getUserOwnedSubscriptions(requestParameters: GetUserOwnedSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscriptions> {
        const response = await this.getUserOwnedSubscriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getiframeUrlTokenForAdminDashboardRaw(requestParameters: GetiframeUrlTokenForAdminDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MetabaseDashboardToken>> {
        if (requestParameters.dashboardIndex === null || requestParameters.dashboardIndex === undefined) {
            throw new runtime.RequiredError('dashboardIndex','Required parameter requestParameters.dashboardIndex was null or undefined when calling getiframeUrlTokenForAdminDashboard.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getiframeUrlTokenForAdminDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin-dashboard/iframeUrlToken/{dashboardIndex}`.replace(`{${"dashboardIndex"}}`, encodeURIComponent(String(requestParameters.dashboardIndex))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetabaseDashboardTokenFromJSON(jsonValue));
    }

    /**
     */
    async getiframeUrlTokenForAdminDashboard(requestParameters: GetiframeUrlTokenForAdminDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MetabaseDashboardToken> {
        const response = await this.getiframeUrlTokenForAdminDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async handleStripeEventRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/stripe-webhook/events`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async handleStripeEvent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.handleStripeEventRaw(initOverrides);
    }

    /**
     */
    async isUserFollowingTenantRaw(requestParameters: IsUserFollowingTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsFollowingTenant>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling isUserFollowingTenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/is-following-tenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsFollowingTenantFromJSON(jsonValue));
    }

    /**
     */
    async isUserFollowingTenant(requestParameters: IsUserFollowingTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsFollowingTenant> {
        const response = await this.isUserFollowingTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async joinGroupRaw(requestParameters: JoinGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling joinGroup.');
        }

        if (requestParameters.groupInfoDto === null || requestParameters.groupInfoDto === undefined) {
            throw new runtime.RequiredError('groupInfoDto','Required parameter requestParameters.groupInfoDto was null or undefined when calling joinGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/join-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupInfoDtoToJSON(requestParameters.groupInfoDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async joinGroup(requestParameters: JoinGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.joinGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async leaveGroupRaw(requestParameters: LeaveGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling leaveGroup.');
        }

        if (requestParameters.groupInfoDto === null || requestParameters.groupInfoDto === undefined) {
            throw new runtime.RequiredError('groupInfoDto','Required parameter requestParameters.groupInfoDto was null or undefined when calling leaveGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/leave-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupInfoDtoToJSON(requestParameters.groupInfoDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async leaveGroup(requestParameters: LeaveGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.leaveGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async likeSocialMediaPostRaw(requestParameters: LikeSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.socialMediaPostIdDto === null || requestParameters.socialMediaPostIdDto === undefined) {
            throw new runtime.RequiredError('socialMediaPostIdDto','Required parameter requestParameters.socialMediaPostIdDto was null or undefined when calling likeSocialMediaPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/like`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialMediaPostIdDtoToJSON(requestParameters.socialMediaPostIdDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async likeSocialMediaPost(requestParameters: LikeSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.likeSocialMediaPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async linkDoctorRaw(requestParameters: LinkDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.linkDoctorDto === null || requestParameters.linkDoctorDto === undefined) {
            throw new runtime.RequiredError('linkDoctorDto','Required parameter requestParameters.linkDoctorDto was null or undefined when calling linkDoctor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor/link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkDoctorDtoToJSON(requestParameters.linkDoctorDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async linkDoctor(requestParameters: LinkDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.linkDoctorRaw(requestParameters, initOverrides);
    }

    /**
     */
    async listDraftNewsStoriesRaw(requestParameters: ListDraftNewsStoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NewsStory>>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling listDraftNewsStories.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling listDraftNewsStories.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news-stories/drafts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsStoryFromJSON));
    }

    /**
     */
    async listDraftNewsStories(requestParameters: ListDraftNewsStoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NewsStory>> {
        const response = await this.listDraftNewsStoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listFollowedOrganisationsRaw(requestParameters: ListFollowedOrganisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupsAndSubscriptionsPerFollowedOrg>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling listFollowedOrganisations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenant/followed-organisations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupsAndSubscriptionsPerFollowedOrgFromJSON(jsonValue));
    }

    /**
     */
    async listFollowedOrganisations(requestParameters: ListFollowedOrganisationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupsAndSubscriptionsPerFollowedOrg> {
        const response = await this.listFollowedOrganisationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listNewsStoriesRaw(requestParameters: ListNewsStoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NewsStory>>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling listNewsStories.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling listNewsStories.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news-stories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsStoryFromJSON));
    }

    /**
     */
    async listNewsStories(requestParameters: ListNewsStoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NewsStory>> {
        const response = await this.listNewsStoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listPinnedNewsStoriesRaw(requestParameters: ListPinnedNewsStoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NewsStory>>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling listPinnedNewsStories.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling listPinnedNewsStories.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news-stories/pinned`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsStoryFromJSON));
    }

    /**
     */
    async listPinnedNewsStories(requestParameters: ListPinnedNewsStoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NewsStory>> {
        const response = await this.listPinnedNewsStoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listSocialMediaPostCategoriesRaw(requestParameters: ListSocialMediaPostCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SocialMediaPostCategory>>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling listSocialMediaPostCategories.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/category`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocialMediaPostCategoryFromJSON));
    }

    /**
     */
    async listSocialMediaPostCategories(requestParameters: ListSocialMediaPostCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SocialMediaPostCategory>> {
        const response = await this.listSocialMediaPostCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listSocialMediaPostsRaw(requestParameters: ListSocialMediaPostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SocialMediaPost>>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling listSocialMediaPosts.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling listSocialMediaPosts.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocialMediaPostFromJSON));
    }

    /**
     */
    async listSocialMediaPosts(requestParameters: ListSocialMediaPostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SocialMediaPost>> {
        const response = await this.listSocialMediaPostsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listSocialMediaPostsCommentsRaw(requestParameters: ListSocialMediaPostsCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SocialMediaComment>>> {
        if (requestParameters.socialMediaPostIdDto === null || requestParameters.socialMediaPostIdDto === undefined) {
            throw new runtime.RequiredError('socialMediaPostIdDto','Required parameter requestParameters.socialMediaPostIdDto was null or undefined when calling listSocialMediaPostsComments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/comment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialMediaPostIdDtoToJSON(requestParameters.socialMediaPostIdDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocialMediaCommentFromJSON));
    }

    /**
     */
    async listSocialMediaPostsComments(requestParameters: ListSocialMediaPostsCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SocialMediaComment>> {
        const response = await this.listSocialMediaPostsCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logUserEventRaw(requestParameters: LogUserEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userEventDto === null || requestParameters.userEventDto === undefined) {
            throw new runtime.RequiredError('userEventDto','Required parameter requestParameters.userEventDto was null or undefined when calling logUserEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-activity/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserEventDtoToJSON(requestParameters.userEventDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logUserEvent(requestParameters: LogUserEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logUserEventRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markSignUpAsDoneRaw(requestParameters: MarkSignUpAsDoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling markSignUpAsDone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/signup/mark-as-done`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markSignUpAsDone(requestParameters: MarkSignUpAsDoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markSignUpAsDoneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notificationsWebhookRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/twilio-webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notificationsWebhook(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsWebhookRaw(initOverrides);
    }

    /**
     */
    async pinNewsStoryRaw(requestParameters: PinNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsStory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pinNewsStory.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling pinNewsStory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news-stories/{id}/pin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsStoryFromJSON(jsonValue));
    }

    /**
     */
    async pinNewsStory(requestParameters: PinNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsStory> {
        const response = await this.pinNewsStoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publishNewsStoryRaw(requestParameters: PublishNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsStory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling publishNewsStory.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling publishNewsStory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news-stories/{id}/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsStoryFromJSON(jsonValue));
    }

    /**
     */
    async publishNewsStory(requestParameters: PublishNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsStory> {
        const response = await this.publishNewsStoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rateDoctorRaw(requestParameters: RateDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.rateDoctorDto === null || requestParameters.rateDoctorDto === undefined) {
            throw new runtime.RequiredError('rateDoctorDto','Required parameter requestParameters.rateDoctorDto was null or undefined when calling rateDoctor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor/rate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RateDoctorDtoToJSON(requestParameters.rateDoctorDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async rateDoctor(requestParameters: RateDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rateDoctorRaw(requestParameters, initOverrides);
    }

    /**
     */
    async registerUserRaw(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.registerUserDto === null || requestParameters.registerUserDto === undefined) {
            throw new runtime.RequiredError('registerUserDto','Required parameter requestParameters.registerUserDto was null or undefined when calling registerUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-creation/register-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterUserDtoToJSON(requestParameters.registerUserDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async registerUser(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async renewCurrentSubscriptionRaw(requestParameters: RenewCurrentSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscription>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling renewCurrentSubscription.');
        }

        if (requestParameters.renewSubscriptionDto === null || requestParameters.renewSubscriptionDto === undefined) {
            throw new runtime.RequiredError('renewSubscriptionDto','Required parameter requestParameters.renewSubscriptionDto was null or undefined when calling renewCurrentSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/renew-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenewSubscriptionDtoToJSON(requestParameters.renewSubscriptionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionFromJSON(jsonValue));
    }

    /**
     */
    async renewCurrentSubscription(requestParameters: RenewCurrentSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscription> {
        const response = await this.renewCurrentSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async retrieveUnseenFeedbacksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserFeedback>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-feedback/retrieve-unseen-feedbacks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFeedbackFromJSON));
    }

    /**
     */
    async retrieveUnseenFeedbacks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserFeedback>> {
        const response = await this.retrieveUnseenFeedbacksRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async saveDoctorRaw(requestParameters: SaveDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling saveDoctor.');
        }

        if (requestParameters.saveDoctorDto === null || requestParameters.saveDoctorDto === undefined) {
            throw new runtime.RequiredError('saveDoctorDto','Required parameter requestParameters.saveDoctorDto was null or undefined when calling saveDoctor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveDoctorDtoToJSON(requestParameters.saveDoctorDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveDoctor(requestParameters: SaveDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveDoctorRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendReportViaEmailRaw(requestParameters: SendReportViaEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling sendReportViaEmail.');
        }

        if (requestParameters.reportDto === null || requestParameters.reportDto === undefined) {
            throw new runtime.RequiredError('reportDto','Required parameter requestParameters.reportDto was null or undefined when calling sendReportViaEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportDtoToJSON(requestParameters.reportDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendReportViaEmail(requestParameters: SendReportViaEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendReportViaEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendTimelineRaw(requestParameters: SendTimelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling sendTimeline.');
        }

        if (requestParameters.timelineDto === null || requestParameters.timelineDto === undefined) {
            throw new runtime.RequiredError('timelineDto','Required parameter requestParameters.timelineDto was null or undefined when calling sendTimeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timeline`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimelineDtoToJSON(requestParameters.timelineDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendTimeline(requestParameters: SendTimelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendTimelineRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setLastActiveTenantRaw(requestParameters: SetLastActiveTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.lastActiveTenant === null || requestParameters.lastActiveTenant === undefined) {
            throw new runtime.RequiredError('lastActiveTenant','Required parameter requestParameters.lastActiveTenant was null or undefined when calling setLastActiveTenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/last-active-tenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LastActiveTenantToJSON(requestParameters.lastActiveTenant),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setLastActiveTenant(requestParameters: SetLastActiveTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setLastActiveTenantRaw(requestParameters, initOverrides);
    }

    /**
     */
    async showFeedbackRaw(requestParameters: ShowFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.feedbacksDto === null || requestParameters.feedbacksDto === undefined) {
            throw new runtime.RequiredError('feedbacksDto','Required parameter requestParameters.feedbacksDto was null or undefined when calling showFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-feedback/show-feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbacksDtoToJSON(requestParameters.feedbacksDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async showFeedback(requestParameters: ShowFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.showFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async submitUserFeedbackRaw(requestParameters: SubmitUserFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submitFeedbackDto === null || requestParameters.submitFeedbackDto === undefined) {
            throw new runtime.RequiredError('submitFeedbackDto','Required parameter requestParameters.submitFeedbackDto was null or undefined when calling submitUserFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-feedback/submit-feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitFeedbackDtoToJSON(requestParameters.submitFeedbackDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async submitUserFeedback(requestParameters: SubmitUserFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitUserFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     */
    async switchSubscriptionRaw(requestParameters: SwitchSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscription>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling switchSubscription.');
        }

        if (requestParameters.switchSubscriptionDto === null || requestParameters.switchSubscriptionDto === undefined) {
            throw new runtime.RequiredError('switchSubscriptionDto','Required parameter requestParameters.switchSubscriptionDto was null or undefined when calling switchSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription/switch-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwitchSubscriptionDtoToJSON(requestParameters.switchSubscriptionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionFromJSON(jsonValue));
    }

    /**
     */
    async switchSubscription(requestParameters: SwitchSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscription> {
        const response = await this.switchSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async unlinkTemporaryTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-creation/unlink-temporary-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unlinkTemporaryToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unlinkTemporaryTokenRaw(initOverrides);
    }

    /**
     */
    async unpinNewsStoryRaw(requestParameters: UnpinNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsStory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unpinNewsStory.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling unpinNewsStory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news-stories/{id}/unpin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsStoryFromJSON(jsonValue));
    }

    /**
     */
    async unpinNewsStory(requestParameters: UnpinNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsStory> {
        const response = await this.unpinNewsStoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDefaultPaymentMethodRaw(requestParameters: UpdateDefaultPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateDefaultPaymentMethod.');
        }

        if (requestParameters.defaultPaymentMethodDto === null || requestParameters.defaultPaymentMethodDto === undefined) {
            throw new runtime.RequiredError('defaultPaymentMethodDto','Required parameter requestParameters.defaultPaymentMethodDto was null or undefined when calling updateDefaultPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stripe-customer/update-payment-method`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DefaultPaymentMethodDtoToJSON(requestParameters.defaultPaymentMethodDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateDefaultPaymentMethod(requestParameters: UpdateDefaultPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDefaultPaymentMethodRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateNewsStoryRaw(requestParameters: UpdateNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsStory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNewsStory.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateNewsStory.');
        }

        if (requestParameters.updateNewsStoryDto === null || requestParameters.updateNewsStoryDto === undefined) {
            throw new runtime.RequiredError('updateNewsStoryDto','Required parameter requestParameters.updateNewsStoryDto was null or undefined when calling updateNewsStory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news-stories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNewsStoryDtoToJSON(requestParameters.updateNewsStoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsStoryFromJSON(jsonValue));
    }

    /**
     */
    async updateNewsStory(requestParameters: UpdateNewsStoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsStory> {
        const response = await this.updateNewsStoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganisationMemebershipsRaw(requestParameters: UpdateOrganisationMemebershipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateOrganisationMemeberships.');
        }

        if (requestParameters.multipleMembershipsDto === null || requestParameters.multipleMembershipsDto === undefined) {
            throw new runtime.RequiredError('multipleMembershipsDto','Required parameter requestParameters.multipleMembershipsDto was null or undefined when calling updateOrganisationMemeberships.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/member-group/update-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleMembershipsDtoToJSON(requestParameters.multipleMembershipsDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateOrganisationMemeberships(requestParameters: UpdateOrganisationMemebershipsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrganisationMemebershipsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateSettingsRaw(requestParameters: UpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Settings>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateSettings.');
        }

        if (requestParameters.updateSettingsDto === null || requestParameters.updateSettingsDto === undefined) {
            throw new runtime.RequiredError('updateSettingsDto','Required parameter requestParameters.updateSettingsDto was null or undefined when calling updateSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/settings`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSettingsDtoToJSON(requestParameters.updateSettingsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsFromJSON(jsonValue));
    }

    /**
     */
    async updateSettings(requestParameters: UpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Settings> {
        const response = await this.updateSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSocialMediaPostRaw(requestParameters: UpdateSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSocialMediaPost.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateSocialMediaPost.');
        }

        if (requestParameters.updateSocialMediaPostDto === null || requestParameters.updateSocialMediaPostDto === undefined) {
            throw new runtime.RequiredError('updateSocialMediaPostDto','Required parameter requestParameters.updateSocialMediaPostDto was null or undefined when calling updateSocialMediaPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSocialMediaPostDtoToJSON(requestParameters.updateSocialMediaPostDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateSocialMediaPost(requestParameters: UpdateSocialMediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSocialMediaPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateSocialMediaPostCategoryRaw(requestParameters: UpdateSocialMediaPostCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialMediaPostCategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSocialMediaPostCategory.');
        }

        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateSocialMediaPostCategory.');
        }

        if (requestParameters.upsertSocialMediaPostCategoryDto === null || requestParameters.upsertSocialMediaPostCategoryDto === undefined) {
            throw new runtime.RequiredError('upsertSocialMediaPostCategoryDto','Required parameter requestParameters.upsertSocialMediaPostCategoryDto was null or undefined when calling updateSocialMediaPostCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/social-media/category/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertSocialMediaPostCategoryDtoToJSON(requestParameters.upsertSocialMediaPostCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialMediaPostCategoryFromJSON(jsonValue));
    }

    /**
     */
    async updateSocialMediaPostCategory(requestParameters: UpdateSocialMediaPostCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialMediaPostCategory> {
        const response = await this.updateSocialMediaPostCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserAttributesRaw(requestParameters: UpdateUserAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateUserAttributesDto>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateUserAttributes.');
        }

        if (requestParameters.updateUserAttributesDto === null || requestParameters.updateUserAttributesDto === undefined) {
            throw new runtime.RequiredError('updateUserAttributesDto','Required parameter requestParameters.updateUserAttributesDto was null or undefined when calling updateUserAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attributes`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserAttributesDtoToJSON(requestParameters.updateUserAttributesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserAttributesDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateUserAttributes(requestParameters: UpdateUserAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateUserAttributesDto> {
        const response = await this.updateUserAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserDetailsRaw(requestParameters: UpdateUserDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling updateUserDetails.');
        }

        if (requestParameters.updateUserDetailsDto === null || requestParameters.updateUserDetailsDto === undefined) {
            throw new runtime.RequiredError('updateUserDetailsDto','Required parameter requestParameters.updateUserDetailsDto was null or undefined when calling updateUserDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (requestParameters.mamaOrganisation !== undefined && requestParameters.mamaOrganisation !== null) {
            headerParameters['mama-organisation'] = String(requestParameters.mamaOrganisation);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/details`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserDetailsDtoToJSON(requestParameters.updateUserDetailsDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateUserDetails(requestParameters: UpdateUserDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserDetailsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userExistsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserExists>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-creation/user-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserExistsFromJSON(jsonValue));
    }

    /**
     */
    async userExists(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserExists> {
        const response = await this.userExistsRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const AttachPaymentMethodMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type AttachPaymentMethodMamaDiseaseEnum = typeof AttachPaymentMethodMamaDiseaseEnum[keyof typeof AttachPaymentMethodMamaDiseaseEnum];
/**
 * @export
 */
export const AttachPaymentMethodMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type AttachPaymentMethodMamaOrganisationEnum = typeof AttachPaymentMethodMamaOrganisationEnum[keyof typeof AttachPaymentMethodMamaOrganisationEnum];
/**
 * @export
 */
export const CancelSubscriptionMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CancelSubscriptionMamaDiseaseEnum = typeof CancelSubscriptionMamaDiseaseEnum[keyof typeof CancelSubscriptionMamaDiseaseEnum];
/**
 * @export
 */
export const CancelSubscriptionMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CancelSubscriptionMamaOrganisationEnum = typeof CancelSubscriptionMamaOrganisationEnum[keyof typeof CancelSubscriptionMamaOrganisationEnum];
/**
 * @export
 */
export const ConnectAccountMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type ConnectAccountMamaDiseaseEnum = typeof ConnectAccountMamaDiseaseEnum[keyof typeof ConnectAccountMamaDiseaseEnum];
/**
 * @export
 */
export const ConnectAccountMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type ConnectAccountMamaOrganisationEnum = typeof ConnectAccountMamaOrganisationEnum[keyof typeof ConnectAccountMamaOrganisationEnum];
/**
 * @export
 */
export const CreateActiveSubscriptionMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateActiveSubscriptionMamaDiseaseEnum = typeof CreateActiveSubscriptionMamaDiseaseEnum[keyof typeof CreateActiveSubscriptionMamaDiseaseEnum];
/**
 * @export
 */
export const CreateActiveSubscriptionMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateActiveSubscriptionMamaOrganisationEnum = typeof CreateActiveSubscriptionMamaOrganisationEnum[keyof typeof CreateActiveSubscriptionMamaOrganisationEnum];
/**
 * @export
 */
export const CreateAndLinkStripeAccountMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateAndLinkStripeAccountMamaDiseaseEnum = typeof CreateAndLinkStripeAccountMamaDiseaseEnum[keyof typeof CreateAndLinkStripeAccountMamaDiseaseEnum];
/**
 * @export
 */
export const CreateAndLinkStripeAccountMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateAndLinkStripeAccountMamaOrganisationEnum = typeof CreateAndLinkStripeAccountMamaOrganisationEnum[keyof typeof CreateAndLinkStripeAccountMamaOrganisationEnum];
/**
 * @export
 */
export const CreateNewsStoryMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateNewsStoryMamaDiseaseEnum = typeof CreateNewsStoryMamaDiseaseEnum[keyof typeof CreateNewsStoryMamaDiseaseEnum];
/**
 * @export
 */
export const CreateNewsStoryMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateNewsStoryMamaOrganisationEnum = typeof CreateNewsStoryMamaOrganisationEnum[keyof typeof CreateNewsStoryMamaOrganisationEnum];
/**
 * @export
 */
export const CreateSocialMediaPostMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateSocialMediaPostMamaDiseaseEnum = typeof CreateSocialMediaPostMamaDiseaseEnum[keyof typeof CreateSocialMediaPostMamaDiseaseEnum];
/**
 * @export
 */
export const CreateSocialMediaPostMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateSocialMediaPostMamaOrganisationEnum = typeof CreateSocialMediaPostMamaOrganisationEnum[keyof typeof CreateSocialMediaPostMamaOrganisationEnum];
/**
 * @export
 */
export const CreateSocialMediaPostCategoryMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateSocialMediaPostCategoryMamaDiseaseEnum = typeof CreateSocialMediaPostCategoryMamaDiseaseEnum[keyof typeof CreateSocialMediaPostCategoryMamaDiseaseEnum];
/**
 * @export
 */
export const CreateSocialMediaPostCategoryMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateSocialMediaPostCategoryMamaOrganisationEnum = typeof CreateSocialMediaPostCategoryMamaOrganisationEnum[keyof typeof CreateSocialMediaPostCategoryMamaOrganisationEnum];
/**
 * @export
 */
export const CreateSubscriptionMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateSubscriptionMamaDiseaseEnum = typeof CreateSubscriptionMamaDiseaseEnum[keyof typeof CreateSubscriptionMamaDiseaseEnum];
/**
 * @export
 */
export const CreateSubscriptionMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateSubscriptionMamaOrganisationEnum = typeof CreateSubscriptionMamaOrganisationEnum[keyof typeof CreateSubscriptionMamaOrganisationEnum];
/**
 * @export
 */
export const CreateUserMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type CreateUserMamaDiseaseEnum = typeof CreateUserMamaDiseaseEnum[keyof typeof CreateUserMamaDiseaseEnum];
/**
 * @export
 */
export const CreateUserMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type CreateUserMamaOrganisationEnum = typeof CreateUserMamaOrganisationEnum[keyof typeof CreateUserMamaOrganisationEnum];
/**
 * @export
 */
export const DeleteNewsStoryMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type DeleteNewsStoryMamaDiseaseEnum = typeof DeleteNewsStoryMamaDiseaseEnum[keyof typeof DeleteNewsStoryMamaDiseaseEnum];
/**
 * @export
 */
export const DeleteNewsStoryMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type DeleteNewsStoryMamaOrganisationEnum = typeof DeleteNewsStoryMamaOrganisationEnum[keyof typeof DeleteNewsStoryMamaOrganisationEnum];
/**
 * @export
 */
export const DeleteSocialMediaPostMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type DeleteSocialMediaPostMamaDiseaseEnum = typeof DeleteSocialMediaPostMamaDiseaseEnum[keyof typeof DeleteSocialMediaPostMamaDiseaseEnum];
/**
 * @export
 */
export const DeleteSocialMediaPostMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type DeleteSocialMediaPostMamaOrganisationEnum = typeof DeleteSocialMediaPostMamaOrganisationEnum[keyof typeof DeleteSocialMediaPostMamaOrganisationEnum];
/**
 * @export
 */
export const DeleteSocialMediaPostCategoryMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type DeleteSocialMediaPostCategoryMamaDiseaseEnum = typeof DeleteSocialMediaPostCategoryMamaDiseaseEnum[keyof typeof DeleteSocialMediaPostCategoryMamaDiseaseEnum];
/**
 * @export
 */
export const DeleteSocialMediaPostCategoryMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type DeleteSocialMediaPostCategoryMamaOrganisationEnum = typeof DeleteSocialMediaPostCategoryMamaOrganisationEnum[keyof typeof DeleteSocialMediaPostCategoryMamaOrganisationEnum];
/**
 * @export
 */
export const FinishedSignUpViaCallMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type FinishedSignUpViaCallMamaDiseaseEnum = typeof FinishedSignUpViaCallMamaDiseaseEnum[keyof typeof FinishedSignUpViaCallMamaDiseaseEnum];
/**
 * @export
 */
export const FinishedSignUpViaCallMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type FinishedSignUpViaCallMamaOrganisationEnum = typeof FinishedSignUpViaCallMamaOrganisationEnum[keyof typeof FinishedSignUpViaCallMamaOrganisationEnum];
/**
 * @export
 */
export const GetCreditCardsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetCreditCardsMamaDiseaseEnum = typeof GetCreditCardsMamaDiseaseEnum[keyof typeof GetCreditCardsMamaDiseaseEnum];
/**
 * @export
 */
export const GetCreditCardsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetCreditCardsMamaOrganisationEnum = typeof GetCreditCardsMamaOrganisationEnum[keyof typeof GetCreditCardsMamaOrganisationEnum];
/**
 * @export
 */
export const GetMarkersMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetMarkersMamaDiseaseEnum = typeof GetMarkersMamaDiseaseEnum[keyof typeof GetMarkersMamaDiseaseEnum];
/**
 * @export
 */
export const GetMarkersMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetMarkersMamaOrganisationEnum = typeof GetMarkersMamaOrganisationEnum[keyof typeof GetMarkersMamaOrganisationEnum];
/**
 * @export
 */
export const GetMeMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetMeMamaDiseaseEnum = typeof GetMeMamaDiseaseEnum[keyof typeof GetMeMamaDiseaseEnum];
/**
 * @export
 */
export const GetMeMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetMeMamaOrganisationEnum = typeof GetMeMamaOrganisationEnum[keyof typeof GetMeMamaOrganisationEnum];
/**
 * @export
 */
export const GetMyDoctorsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetMyDoctorsMamaDiseaseEnum = typeof GetMyDoctorsMamaDiseaseEnum[keyof typeof GetMyDoctorsMamaDiseaseEnum];
/**
 * @export
 */
export const GetMyDoctorsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetMyDoctorsMamaOrganisationEnum = typeof GetMyDoctorsMamaOrganisationEnum[keyof typeof GetMyDoctorsMamaOrganisationEnum];
/**
 * @export
 */
export const GetPriceListMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetPriceListMamaDiseaseEnum = typeof GetPriceListMamaDiseaseEnum[keyof typeof GetPriceListMamaDiseaseEnum];
/**
 * @export
 */
export const GetPriceListMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetPriceListMamaOrganisationEnum = typeof GetPriceListMamaOrganisationEnum[keyof typeof GetPriceListMamaOrganisationEnum];
/**
 * @export
 */
export const GetProrationInvoiceMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetProrationInvoiceMamaDiseaseEnum = typeof GetProrationInvoiceMamaDiseaseEnum[keyof typeof GetProrationInvoiceMamaDiseaseEnum];
/**
 * @export
 */
export const GetProrationInvoiceMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetProrationInvoiceMamaOrganisationEnum = typeof GetProrationInvoiceMamaOrganisationEnum[keyof typeof GetProrationInvoiceMamaOrganisationEnum];
/**
 * @export
 */
export const GetStripeAccountMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetStripeAccountMamaDiseaseEnum = typeof GetStripeAccountMamaDiseaseEnum[keyof typeof GetStripeAccountMamaDiseaseEnum];
/**
 * @export
 */
export const GetStripeAccountMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetStripeAccountMamaOrganisationEnum = typeof GetStripeAccountMamaOrganisationEnum[keyof typeof GetStripeAccountMamaOrganisationEnum];
/**
 * @export
 */
export const GetStripeCustomerMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetStripeCustomerMamaDiseaseEnum = typeof GetStripeCustomerMamaDiseaseEnum[keyof typeof GetStripeCustomerMamaDiseaseEnum];
/**
 * @export
 */
export const GetStripeCustomerMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetStripeCustomerMamaOrganisationEnum = typeof GetStripeCustomerMamaOrganisationEnum[keyof typeof GetStripeCustomerMamaOrganisationEnum];
/**
 * @export
 */
export const GetTenantSettingsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetTenantSettingsMamaDiseaseEnum = typeof GetTenantSettingsMamaDiseaseEnum[keyof typeof GetTenantSettingsMamaDiseaseEnum];
/**
 * @export
 */
export const GetTenantSettingsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetTenantSettingsMamaOrganisationEnum = typeof GetTenantSettingsMamaOrganisationEnum[keyof typeof GetTenantSettingsMamaOrganisationEnum];
/**
 * @export
 */
export const GetUserMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetUserMamaDiseaseEnum = typeof GetUserMamaDiseaseEnum[keyof typeof GetUserMamaDiseaseEnum];
/**
 * @export
 */
export const GetUserMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetUserMamaOrganisationEnum = typeof GetUserMamaOrganisationEnum[keyof typeof GetUserMamaOrganisationEnum];
/**
 * @export
 */
export const GetUserOwnedSubscriptionsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetUserOwnedSubscriptionsMamaDiseaseEnum = typeof GetUserOwnedSubscriptionsMamaDiseaseEnum[keyof typeof GetUserOwnedSubscriptionsMamaDiseaseEnum];
/**
 * @export
 */
export const GetUserOwnedSubscriptionsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetUserOwnedSubscriptionsMamaOrganisationEnum = typeof GetUserOwnedSubscriptionsMamaOrganisationEnum[keyof typeof GetUserOwnedSubscriptionsMamaOrganisationEnum];
/**
 * @export
 */
export const GetiframeUrlTokenForAdminDashboardMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type GetiframeUrlTokenForAdminDashboardMamaDiseaseEnum = typeof GetiframeUrlTokenForAdminDashboardMamaDiseaseEnum[keyof typeof GetiframeUrlTokenForAdminDashboardMamaDiseaseEnum];
/**
 * @export
 */
export const GetiframeUrlTokenForAdminDashboardMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GetiframeUrlTokenForAdminDashboardMamaOrganisationEnum = typeof GetiframeUrlTokenForAdminDashboardMamaOrganisationEnum[keyof typeof GetiframeUrlTokenForAdminDashboardMamaOrganisationEnum];
/**
 * @export
 */
export const IsUserFollowingTenantMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type IsUserFollowingTenantMamaDiseaseEnum = typeof IsUserFollowingTenantMamaDiseaseEnum[keyof typeof IsUserFollowingTenantMamaDiseaseEnum];
/**
 * @export
 */
export const IsUserFollowingTenantMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type IsUserFollowingTenantMamaOrganisationEnum = typeof IsUserFollowingTenantMamaOrganisationEnum[keyof typeof IsUserFollowingTenantMamaOrganisationEnum];
/**
 * @export
 */
export const JoinGroupMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type JoinGroupMamaDiseaseEnum = typeof JoinGroupMamaDiseaseEnum[keyof typeof JoinGroupMamaDiseaseEnum];
/**
 * @export
 */
export const JoinGroupMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type JoinGroupMamaOrganisationEnum = typeof JoinGroupMamaOrganisationEnum[keyof typeof JoinGroupMamaOrganisationEnum];
/**
 * @export
 */
export const LeaveGroupMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type LeaveGroupMamaDiseaseEnum = typeof LeaveGroupMamaDiseaseEnum[keyof typeof LeaveGroupMamaDiseaseEnum];
/**
 * @export
 */
export const LeaveGroupMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type LeaveGroupMamaOrganisationEnum = typeof LeaveGroupMamaOrganisationEnum[keyof typeof LeaveGroupMamaOrganisationEnum];
/**
 * @export
 */
export const ListDraftNewsStoriesMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type ListDraftNewsStoriesMamaDiseaseEnum = typeof ListDraftNewsStoriesMamaDiseaseEnum[keyof typeof ListDraftNewsStoriesMamaDiseaseEnum];
/**
 * @export
 */
export const ListDraftNewsStoriesMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type ListDraftNewsStoriesMamaOrganisationEnum = typeof ListDraftNewsStoriesMamaOrganisationEnum[keyof typeof ListDraftNewsStoriesMamaOrganisationEnum];
/**
 * @export
 */
export const ListFollowedOrganisationsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type ListFollowedOrganisationsMamaDiseaseEnum = typeof ListFollowedOrganisationsMamaDiseaseEnum[keyof typeof ListFollowedOrganisationsMamaDiseaseEnum];
/**
 * @export
 */
export const ListNewsStoriesMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type ListNewsStoriesMamaDiseaseEnum = typeof ListNewsStoriesMamaDiseaseEnum[keyof typeof ListNewsStoriesMamaDiseaseEnum];
/**
 * @export
 */
export const ListNewsStoriesMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type ListNewsStoriesMamaOrganisationEnum = typeof ListNewsStoriesMamaOrganisationEnum[keyof typeof ListNewsStoriesMamaOrganisationEnum];
/**
 * @export
 */
export const ListPinnedNewsStoriesMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type ListPinnedNewsStoriesMamaDiseaseEnum = typeof ListPinnedNewsStoriesMamaDiseaseEnum[keyof typeof ListPinnedNewsStoriesMamaDiseaseEnum];
/**
 * @export
 */
export const ListPinnedNewsStoriesMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type ListPinnedNewsStoriesMamaOrganisationEnum = typeof ListPinnedNewsStoriesMamaOrganisationEnum[keyof typeof ListPinnedNewsStoriesMamaOrganisationEnum];
/**
 * @export
 */
export const ListSocialMediaPostCategoriesMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type ListSocialMediaPostCategoriesMamaDiseaseEnum = typeof ListSocialMediaPostCategoriesMamaDiseaseEnum[keyof typeof ListSocialMediaPostCategoriesMamaDiseaseEnum];
/**
 * @export
 */
export const ListSocialMediaPostCategoriesMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type ListSocialMediaPostCategoriesMamaOrganisationEnum = typeof ListSocialMediaPostCategoriesMamaOrganisationEnum[keyof typeof ListSocialMediaPostCategoriesMamaOrganisationEnum];
/**
 * @export
 */
export const ListSocialMediaPostsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type ListSocialMediaPostsMamaDiseaseEnum = typeof ListSocialMediaPostsMamaDiseaseEnum[keyof typeof ListSocialMediaPostsMamaDiseaseEnum];
/**
 * @export
 */
export const ListSocialMediaPostsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type ListSocialMediaPostsMamaOrganisationEnum = typeof ListSocialMediaPostsMamaOrganisationEnum[keyof typeof ListSocialMediaPostsMamaOrganisationEnum];
/**
 * @export
 */
export const MarkSignUpAsDoneMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type MarkSignUpAsDoneMamaDiseaseEnum = typeof MarkSignUpAsDoneMamaDiseaseEnum[keyof typeof MarkSignUpAsDoneMamaDiseaseEnum];
/**
 * @export
 */
export const MarkSignUpAsDoneMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type MarkSignUpAsDoneMamaOrganisationEnum = typeof MarkSignUpAsDoneMamaOrganisationEnum[keyof typeof MarkSignUpAsDoneMamaOrganisationEnum];
/**
 * @export
 */
export const PinNewsStoryMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type PinNewsStoryMamaDiseaseEnum = typeof PinNewsStoryMamaDiseaseEnum[keyof typeof PinNewsStoryMamaDiseaseEnum];
/**
 * @export
 */
export const PinNewsStoryMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type PinNewsStoryMamaOrganisationEnum = typeof PinNewsStoryMamaOrganisationEnum[keyof typeof PinNewsStoryMamaOrganisationEnum];
/**
 * @export
 */
export const PublishNewsStoryMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type PublishNewsStoryMamaDiseaseEnum = typeof PublishNewsStoryMamaDiseaseEnum[keyof typeof PublishNewsStoryMamaDiseaseEnum];
/**
 * @export
 */
export const PublishNewsStoryMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type PublishNewsStoryMamaOrganisationEnum = typeof PublishNewsStoryMamaOrganisationEnum[keyof typeof PublishNewsStoryMamaOrganisationEnum];
/**
 * @export
 */
export const RenewCurrentSubscriptionMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type RenewCurrentSubscriptionMamaDiseaseEnum = typeof RenewCurrentSubscriptionMamaDiseaseEnum[keyof typeof RenewCurrentSubscriptionMamaDiseaseEnum];
/**
 * @export
 */
export const RenewCurrentSubscriptionMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type RenewCurrentSubscriptionMamaOrganisationEnum = typeof RenewCurrentSubscriptionMamaOrganisationEnum[keyof typeof RenewCurrentSubscriptionMamaOrganisationEnum];
/**
 * @export
 */
export const SaveDoctorMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type SaveDoctorMamaDiseaseEnum = typeof SaveDoctorMamaDiseaseEnum[keyof typeof SaveDoctorMamaDiseaseEnum];
/**
 * @export
 */
export const SaveDoctorMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type SaveDoctorMamaOrganisationEnum = typeof SaveDoctorMamaOrganisationEnum[keyof typeof SaveDoctorMamaOrganisationEnum];
/**
 * @export
 */
export const SendReportViaEmailMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type SendReportViaEmailMamaDiseaseEnum = typeof SendReportViaEmailMamaDiseaseEnum[keyof typeof SendReportViaEmailMamaDiseaseEnum];
/**
 * @export
 */
export const SendReportViaEmailMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type SendReportViaEmailMamaOrganisationEnum = typeof SendReportViaEmailMamaOrganisationEnum[keyof typeof SendReportViaEmailMamaOrganisationEnum];
/**
 * @export
 */
export const SendTimelineMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type SendTimelineMamaDiseaseEnum = typeof SendTimelineMamaDiseaseEnum[keyof typeof SendTimelineMamaDiseaseEnum];
/**
 * @export
 */
export const SendTimelineMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type SendTimelineMamaOrganisationEnum = typeof SendTimelineMamaOrganisationEnum[keyof typeof SendTimelineMamaOrganisationEnum];
/**
 * @export
 */
export const SwitchSubscriptionMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type SwitchSubscriptionMamaDiseaseEnum = typeof SwitchSubscriptionMamaDiseaseEnum[keyof typeof SwitchSubscriptionMamaDiseaseEnum];
/**
 * @export
 */
export const SwitchSubscriptionMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type SwitchSubscriptionMamaOrganisationEnum = typeof SwitchSubscriptionMamaOrganisationEnum[keyof typeof SwitchSubscriptionMamaOrganisationEnum];
/**
 * @export
 */
export const UnpinNewsStoryMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UnpinNewsStoryMamaDiseaseEnum = typeof UnpinNewsStoryMamaDiseaseEnum[keyof typeof UnpinNewsStoryMamaDiseaseEnum];
/**
 * @export
 */
export const UnpinNewsStoryMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UnpinNewsStoryMamaOrganisationEnum = typeof UnpinNewsStoryMamaOrganisationEnum[keyof typeof UnpinNewsStoryMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateDefaultPaymentMethodMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateDefaultPaymentMethodMamaDiseaseEnum = typeof UpdateDefaultPaymentMethodMamaDiseaseEnum[keyof typeof UpdateDefaultPaymentMethodMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateDefaultPaymentMethodMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateDefaultPaymentMethodMamaOrganisationEnum = typeof UpdateDefaultPaymentMethodMamaOrganisationEnum[keyof typeof UpdateDefaultPaymentMethodMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateNewsStoryMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateNewsStoryMamaDiseaseEnum = typeof UpdateNewsStoryMamaDiseaseEnum[keyof typeof UpdateNewsStoryMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateNewsStoryMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateNewsStoryMamaOrganisationEnum = typeof UpdateNewsStoryMamaOrganisationEnum[keyof typeof UpdateNewsStoryMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateOrganisationMemebershipsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateOrganisationMemebershipsMamaDiseaseEnum = typeof UpdateOrganisationMemebershipsMamaDiseaseEnum[keyof typeof UpdateOrganisationMemebershipsMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateOrganisationMemebershipsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateOrganisationMemebershipsMamaOrganisationEnum = typeof UpdateOrganisationMemebershipsMamaOrganisationEnum[keyof typeof UpdateOrganisationMemebershipsMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateSettingsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateSettingsMamaDiseaseEnum = typeof UpdateSettingsMamaDiseaseEnum[keyof typeof UpdateSettingsMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateSocialMediaPostMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateSocialMediaPostMamaDiseaseEnum = typeof UpdateSocialMediaPostMamaDiseaseEnum[keyof typeof UpdateSocialMediaPostMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateSocialMediaPostMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateSocialMediaPostMamaOrganisationEnum = typeof UpdateSocialMediaPostMamaOrganisationEnum[keyof typeof UpdateSocialMediaPostMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateSocialMediaPostCategoryMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateSocialMediaPostCategoryMamaDiseaseEnum = typeof UpdateSocialMediaPostCategoryMamaDiseaseEnum[keyof typeof UpdateSocialMediaPostCategoryMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateSocialMediaPostCategoryMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateSocialMediaPostCategoryMamaOrganisationEnum = typeof UpdateSocialMediaPostCategoryMamaOrganisationEnum[keyof typeof UpdateSocialMediaPostCategoryMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateUserAttributesMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateUserAttributesMamaDiseaseEnum = typeof UpdateUserAttributesMamaDiseaseEnum[keyof typeof UpdateUserAttributesMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateUserAttributesMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateUserAttributesMamaOrganisationEnum = typeof UpdateUserAttributesMamaOrganisationEnum[keyof typeof UpdateUserAttributesMamaOrganisationEnum];
/**
 * @export
 */
export const UpdateUserDetailsMamaDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    GravesDisease: 'GRAVES_DISEASE',
    Opade: 'OPADE'
} as const;
export type UpdateUserDetailsMamaDiseaseEnum = typeof UpdateUserDetailsMamaDiseaseEnum[keyof typeof UpdateUserDetailsMamaDiseaseEnum];
/**
 * @export
 */
export const UpdateUserDetailsMamaOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpdateUserDetailsMamaOrganisationEnum = typeof UpdateUserDetailsMamaOrganisationEnum[keyof typeof UpdateUserDetailsMamaOrganisationEnum];
