import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { CardDataGuard } from "shared/model/data-collection/guard/card-data-guard/card-data-guard";
import { twMerge } from "tailwind-merge";

import { toTenantIdHeader, useApiMutation } from "../../../../api/use-api";
import { GhostButton } from "../../../../components/form/button";
import { Text } from "../../../../components/text";
import { useT } from "../../../../i18n/use-t";
import { ME_KEY } from "../../../../types/query-keys";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { useUser } from "../../../../util/use-user";
import { DataGuardImplementation } from "../types";

export const cardGuardImplementation: DataGuardImplementation = {
  useWasCompleted: () => {
    const user = useUser();
    return !!user?.userAttributes?.hasSeenAllCards;
  },
  Component: ({ config }) => {
    return <CardComponent config={config as CardDataGuard} />;
  },
};

const CardComponent: React.FC<{ config: CardDataGuard }> = ({ config }) => {
  const queryClient = useQueryClient();
  const tenantId = useTenantId();
  const t = useT();

  const [activeCardIndex, setActiveCardIndex] = useState(0);

  const { mutate: updateUserAttributes } = useApiMutation(
    "backend",
    (api) => () =>
      api.updateUserAttributes({
        updateUserAttributesDto: { hasSeenAllCards: true },
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    { successMessage: { tx: "profile.updateSuccessful" }, trackErrors: true },
    {
      onSuccess() {
        config.goToGuard?.((config.currentGuard ?? 0) + 1);
        queryClient.invalidateQueries(ME_KEY(tenantId));
      },
    },
  );

  return (
    <div className="flex h-full flex-col items-center justify-center p-5">
      <div className="rounded-xl border-2 border-primary p-5">
        {config.cards.map(
          (card, index) =>
            activeCardIndex === index && (
              <div
                className="flex max-w-lg flex-col items-center gap-5"
                key={card.title}
              >
                <Text className="text-2xl md:text-4xl" tx={card.title} />
                <img
                  src={t({ tx: card.image })}
                  className="h-64 md:h-80 lg:h-96"
                />
                <Text
                  tx={card.description}
                  className="text-center text-xl md:text-2xl"
                />
              </div>
            ),
        )}
        <div className="mt-6 flex items-center justify-between gap-10">
          <div className="flex-1" />
          <div className="flex flex-1 gap-3">
            {config.cards.map((_, idx) => (
              <div
                key={idx}
                className={twMerge(
                  "h-4 w-4 shrink-0 grow-0 rounded-full bg-neutral",
                  idx === activeCardIndex && "bg-primary",
                )}
              />
            ))}
          </div>
          <div className="flex flex-1 justify-end gap-3">
            <GhostButton
              text="<"
              className={twMerge(
                "h-12 w-12 rounded-full border-2 border-slate-300",
                activeCardIndex > 0
                  ? "inherit"
                  : "pointer-events-none opacity-0",
              )}
              onClick={() => setActiveCardIndex(activeCardIndex - 1)}
            />

            <GhostButton
              text=">"
              className={twMerge(
                "h-12 w-12 rounded-full border-2",
                activeCardIndex === config.cards.length - 1
                  ? "border-primary"
                  : "border-neutral",
              )}
              onClick={() => {
                activeCardIndex === config.cards.length - 1
                  ? updateUserAttributes()
                  : setActiveCardIndex(activeCardIndex + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
