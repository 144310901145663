import { useState } from "react";
import PhoneInput from "react-phone-number-input";

import { BasicWithButton } from "./fragments/basic-with-button";
import { ChatOptionComponentProps } from "../lookup";

export const PhoneTextInput: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <BasicWithButton
      sendResponse={() => {
        sendResponse({ message: phoneNumber, selection: [phoneNumber] });
        setPhoneNumber("");
        return { hasResponseBeenSent: true };
      }}
      message={phoneNumber}
    >
      <PhoneInput
        value={phoneNumber}
        className="input-bordered input"
        onChange={(phone) => setPhoneNumber(phone ? phone : "")}
      />
    </BasicWithButton>
  );
};
