/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ConversationWithMessages,
  FrontendQuestionNodeInfo,
  MessageWithType,
  QuestionNodeInfo,
  WebSocketRequest,
  WebSocketResponseEventData,
} from "shared/model/websocket/schema";
import { TranslationFeKey } from "shared/types/translation-key";
import { twMerge } from "tailwind-merge";

import { FallbackLookup, ValidationFunction } from "./drawer/lookup";
import { SignUpDrawer } from "./drawer/signup";
import { MessageList } from "./message-list";
import { IUseWebSocket } from "../../../../api/types/websocket";
import { useStore } from "../../../../models/helpers";
import { MimeMainType } from "../../../../types/mime-main-type";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { PlainError } from "../../../events/plain-error";
import { LoadingScreen } from "../../../loading-screen";
import { LoadingIndicator } from "../../../loading-spinner";
import { ChatCompletion } from "../../generic/chat-completion";
import { ChatMessage, ChatRole, ChatUserMediaRecord } from "../../types";

export type ConversationProps = {
  chatDrawerComponents: FallbackLookup;
  useAgnosticWebSocket: AgnosticWebSocket;
  conversation: ConversationWithMessages;
  shouldShowChatCompletion: boolean;
  userChatColors?: { bubbleColor?: string; textColor?: string };
  triggerSignUp?: (props: { email: string; name: string }) => Promise<boolean>;
};

export type MessageProps = {
  message: string;
  selection: string[];
  base64Images?: string[];
  base64Audio?: string;
};

export type OnMessageSendFunction = (messageProps: MessageProps) => {
  hasResponseBeenSent: boolean;
};

export type EmailNameTuple = {
  email?: string;
  name?: string;
};

export const Conversation: React.FC<ConversationProps> = ({
  chatDrawerComponents,
  useAgnosticWebSocket,
  conversation,
  shouldShowChatCompletion,
  userChatColors,
  triggerSignUp,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const store = useStore();
  const { disease } = useTenantId();
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [chatLoadingMessage, setChatLoadingMessage] =
    useState<TranslationFeKey>();
  const [signUpButtonHasTimeout, setSignUpButtonHasTimeout] = useState(false);
  const validationFunctionRef = useRef<ValidationFunction | undefined>();
  const emailNameTupleRef = useRef<EmailNameTuple>({});
  const drawerRef = useRef<HTMLDivElement>(null);
  const lastMessage = useMemo(() => chatMessages.at(-1), [chatMessages]);
  const currentFeKeyQuestionNodeInfo: FrontendQuestionNodeInfo = useMemo(
    () => lastMessage?.questionNodeInfo ?? { type: "QUESTION" },
    [lastMessage],
  );

  useEffect(
    function SetUpChatMessagesOnInitialLoad() {
      const lChatMessages = conversation.messages.map(
        transformMessageToChatMessage,
      );
      emailNameTupleRef.current = lChatMessages.reduce(
        maybeExtractEmailNameTuple,
        {},
      );
      setChatMessages(lChatMessages);
    },
    [conversation],
  );

  const isAtSignupStepRef = useMemo(
    () => ({
      get current() {
        const { email, name } = emailNameTupleRef.current;
        return triggerSignUp && Boolean(email && name);
      },
    }),
    [triggerSignUp],
  );

  const maybeTriggerSignUp = useCallback(async () => {
    if (!triggerSignUp || !isAtSignupStepRef.current) return;

    const { email, name } = emailNameTupleRef.current;
    if (!(email && name)) return;

    const isSignupSuccessful = await triggerSignUp({ email, name });

    if (isSignupSuccessful) {
      setSignUpButtonHasTimeout(true);
    }
  }, [isAtSignupStepRef, triggerSignUp]);

  const appendMessageToChat = useCallback((message: ChatMessage) => {
    emailNameTupleRef.current = maybeExtractEmailNameTuple(
      emailNameTupleRef.current ?? {},
      message,
    );
    setChatMessages((prev) => [...prev, message]);
  }, []);

  const popMessageFromChat = () => setChatMessages((prev) => prev.slice(0, -1));

  const { sendMessage: sendWebSocketMessage, isReady: isWebSocketReady } =
    useAgnosticWebSocket({
      query: { language, disease, conversation_id: conversation.id },
      onResponse: async (event) => {
        if (!event) return;

        const message = event?.data.message;
        const key = event?.data.key;
        setChatLoadingMessage(key);

        if (event.type == "message" && !event.data.success) {
          store.addToastEvent(
            new PlainError({
              text:
                event.data.error?.message ?? "something unexpected happened",
            }),
          );
          popMessageFromChat();
          return;
        }

        if (!message) return;
        appendMessageToChat(transformMessageToChatMessage(message));
        setChatLoadingMessage(undefined);
      },
    });

  const isChatBusy =
    lastMessage?.role === ChatRole.USER && !isAtSignupStepRef.current;

  const onSendMessage: OnMessageSendFunction = useCallback(
    ({ message, base64Images, base64Audio, selection }) => {
      if (isChatBusy) return { hasResponseBeenSent: false };

      let messageToSend = message;
      if (
        !messageToSend &&
        (!base64Images || !base64Images.length) &&
        !base64Audio
      )
        return { hasResponseBeenSent: false };

      const media = combineUploadsToMedia(base64Images, base64Audio);
      if (base64Audio) {
        messageToSend = "";
      }

      if (validationFunctionRef.current) {
        const { ok, message: errorMessage } =
          validationFunctionRef.current(messageToSend);

        if (!ok) {
          store.addToastEvent(
            new PlainError({
              tx: errorMessage?.tx,
              txData: errorMessage?.txData,
            }),
          );
          return { hasResponseBeenSent: false };
        }
      }

      appendMessageToChat({
        media: media,
        role: ChatRole.USER,
        content: {
          text: messageToSend,
        },
        questionNodeInfo: currentFeKeyQuestionNodeInfo,
      });

      sendWebSocketMessage({
        type: "message",
        data: {
          questionNodeInfo: currentFeKeyQuestionNodeInfo,
          metadata: {
            selections: selection,
          },
          audio: base64Audio,
          images: base64Images,
          message: messageToSend,
        },
      });

      maybeTriggerSignUp();

      return { hasResponseBeenSent: true };
    },
    [
      appendMessageToChat,
      currentFeKeyQuestionNodeInfo,
      isChatBusy,
      maybeTriggerSignUp,
      sendWebSocketMessage,
      store,
    ],
  );

  const Component = useMemo(() => {
    if (!currentFeKeyQuestionNodeInfo) return null;

    const [UnInitializedDrawerComponent, validation] = chatDrawerComponents.get(
      [
        currentFeKeyQuestionNodeInfo.type,
        currentFeKeyQuestionNodeInfo?.subtype,
      ],
    );
    validationFunctionRef.current = validation;

    const DrawerComponent = (
      <UnInitializedDrawerComponent
        sendResponse={onSendMessage}
        choices={currentFeKeyQuestionNodeInfo?.choices ?? []}
      />
    );

    return shouldShowChatCompletion ? (
      <ChatCompletion conversationId={conversation.id}>
        {DrawerComponent}
      </ChatCompletion>
    ) : (
      DrawerComponent
    );
  }, [
    chatDrawerComponents,
    conversation.id,
    currentFeKeyQuestionNodeInfo,
    onSendMessage,
    shouldShowChatCompletion,
  ]);

  const isLoading = !isWebSocketReady || !chatMessages;

  return isLoading || !Component || !currentFeKeyQuestionNodeInfo ? (
    <LoadingScreen message={{ tx: "chat.loadingInitialChat" }} />
  ) : (
    <div className="flex h-full w-full flex-col items-center">
      <div className="flex h-full w-full flex-col items-center justify-between">
        <div className="mb-10 w-11/12 max-w-[1600px] md:w-9/12">
          <MessageList
            chatMessages={chatMessages}
            isChatBusy={isChatBusy}
            chatLoadingMessage={chatLoadingMessage}
            userChatColors={userChatColors}
          />
        </div>

        <div
          ref={drawerRef}
          className={twMerge(
            "sticky bottom-0 left-0 flex w-full flex-row items-center justify-center bg-white p-4 drop-shadow-2xl",
            isChatBusy && "pointer-events-none select-none",
          )}
        >
          <div
            className={twMerge(
              isChatBusy
                ? "absolute z-10 h-full w-full rounded-xl bg-gray-200 opacity-50"
                : "hidden",
            )}
          />
          {isAtSignupStepRef.current && !signUpButtonHasTimeout ? (
            <LoadingIndicator as="spinner" />
          ) : isAtSignupStepRef.current && signUpButtonHasTimeout ? (
            <SignUpDrawer
              hasInitialTimeout={signUpButtonHasTimeout}
              reTriggerEmail={maybeTriggerSignUp}
            />
          ) : (
            Component
          )}
        </div>
      </div>
    </div>
  );
};

type OnWebSocketResponse = (
  event?: MessageEvent<WebSocketResponseEventData>,
) => Promise<void> | void;

export type AgnosticWebSocket = (params: {
  query: {
    language: string;
    disease: string;
    conversation_id: string;
  };
  onResponse: OnWebSocketResponse;
}) => IUseWebSocket<WebSocketRequest>;

const combineUploadsToMedia = (
  base64Images?: string[],
  base64Audio?: string,
): ChatUserMediaRecord[] => [
  ...(base64Audio ? [{ mainType: MimeMainType.AUDIO, path: base64Audio }] : []),
  ...(base64Images
    ? base64Images.map((b64Image) => ({
        mainType: MimeMainType.IMAGE,
        path: b64Image,
      }))
    : []),
];

const maybeExtractEmailNameTuple = (
  emailNameTuple: EmailNameTuple,
  chatMessage: ChatMessage,
): EmailNameTuple => {
  if (
    chatMessage.role === ChatRole.USER &&
    chatMessage.questionNodeInfo?.type === "SIGNUP"
  ) {
    emailNameTuple.email = chatMessage.content.text;
  }

  if (
    chatMessage.role === ChatRole.USER &&
    chatMessage.questionNodeInfo?.subtype === "NAME"
  ) {
    emailNameTuple.name = chatMessage.content.text;
  }

  return emailNameTuple;
};

const transformMessageToChatMessage = ({
  media,
  contentLocalized,
  role,
  questionNodeInfo,
}: MessageWithType): ChatMessage => {
  const isAudio = media?.some((m) => m.mainType === "audio");

  return {
    content: {
      text: !isAudio ? contentLocalized : "",
    },
    role: role as ChatRole,
    questionNodeInfo: replaceChoicesWithTranslationKeys(questionNodeInfo),
    media,
  };
};

const replaceChoicesWithTranslationKeys = (
  questionNodeInfo: QuestionNodeInfo,
): FrontendQuestionNodeInfo => {
  const maybeChoiceFeKeys = questionNodeInfo.choices?.map(
    (choice) =>
      `graph.choice.${questionNodeInfo.subtype}.${choice}` as TranslationFeKey,
  );

  return {
    type: questionNodeInfo.type,
    subtype: questionNodeInfo.subtype,
    choices: maybeChoiceFeKeys,
  };
};
