// from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation (accessed 2024-11-15) + filter out email addresses that start with special characters
export const isEmailRegex =
  /^[a-zA-Z0-9]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const isUrlRegex =
  /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const isPostalCodeRegexJSON = {
  default: /^\d{5}$/,
  germany: /^\d{5}$/,
  italy: /^\d{5}|\d{2}-\d{3}$/,
};

export const isOpadeIdRegex = /^\d{6}$/;
