import { PropsWithChildren } from "react";

import { Button } from "../../../../../../form/button";
import { ChatOptionComponentProps } from "../../lookup";
import { useOnEnterPressed } from "../../use-submit-on-enter";

export const BasicWithButton: React.FC<
  PropsWithChildren<Pick<ChatOptionComponentProps, "sendResponse">> & {
    message: string;
    selection?: string[];
  }
> = ({ sendResponse, children, message, selection }) => {
  useOnEnterPressed(() => sendResponse({ message, selection: [message] }));

  return (
    <div className="flex w-full flex-row items-center gap-4 md:w-[60%] lg:w-[50%]">
      {children}
      <Button
        isDisabled={message.length === 0}
        className="self-center"
        icon="paperPlane"
        onClick={() =>
          sendResponse({
            message,
            selection: selection ? selection : [message],
          })
        }
      />
    </div>
  );
};
