import { useEffect } from "react";

import { useCognitoConfiguration } from "./use-cognito-configuration";
import { useAuthStateStore } from "../../../auth-state-store-context";
import { AuthStateEnum } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { routesWithoutAutoSignInForward } from "../routes";
import { useTenantConfig } from "~/tenant-settings";

export const useCognitoAutoSignIn = (): void => {
  const configurationState = useCognitoConfiguration();
  const navigate = useAuthNavigate();
  const authStateStore = useAuthStateStore();
  const { homePage } = useTenantConfig();

  useEffect(() => {
    if (
      configurationState === "ready" &&
      !routesWithoutAutoSignInForward.some((route) =>
        window.location.pathname.endsWith(route),
      )
    ) {
      authStateStore.setState(AuthStateEnum.LOADING);
      authStateStore
        .probeIfUserIsSignedIn()
        .then(() =>
          navigate({ to: { type: "uri", uri: homePage }, replace: true }),
        )
        .catch(() => {});
    }
  }, [authStateStore, configurationState, homePage, navigate]);
};
